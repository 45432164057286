var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"applyActivity"},[_c('v-list',{ref:"list",attrs:{"finished":_vm.finished,"finishedText":_vm.finishedText,"commonOperaParams":_vm.commonOperaParams},on:{"onLoad":_vm.onLoad}},_vm._l((_vm.listData),function(item,index){return _c('div',{key:index},[_c('div',{staticClass:"activityItem",on:{"click":function($event){return _vm.toDetail(item)}}},[_c('div',{staticClass:"activityItem-t"},[_c('img',{attrs:{"src":item.activityPicture,"alt":""}})]),_c('div',{staticClass:"activityItem-c"},[_c('div',{staticClass:"title"},[(item.isApplied == 1)?_c('div',{staticClass:"title-l"},[_c('img',{attrs:{"src":require("./img/signUp.png"),"alt":""}}),_c('span',[_vm._v("已报名")])]):_vm._e(),_c('div',{staticClass:"title-r"},[_vm._v(_vm._s(item.activityName))]),(item.activityCategory == 1)?_c('div',{staticClass:"status-red"},[_vm._v(" 热门活动 ")]):(item.activityCategory == 20)?_c('div',{staticClass:"status-blue"},[_vm._v(" 时间银行 ")]):(item.activityCategory == 19)?_c('div',{staticClass:"status-blue"},[_vm._v(" 幸福学堂 ")]):_vm._e()]),_c('div',{staticClass:"address"},[_vm._v(_vm._s(item.activityAddress))]),_c('p',{staticClass:"p-apply"},[_vm._v("报名开始时间："+_vm._s(item.applyStartDate))]),_c('p',{staticClass:"p-activity"},[_vm._v("活动开始时间："+_vm._s(item.startDate))])]),_c('div',{staticClass:"activityItem-b"},[_c('div',{staticClass:"operate"},[_c('div',{staticClass:"operate-l"},[_c('div',{staticClass:"people"},[_c('div',{staticClass:"people-l"},[_c('img',{attrs:{"src":require("./img/newPeople.png"),"alt":""}})]),_c('div',{staticClass:"people-r"},[_vm._v(_vm._s(item.applyUserCount || 0))])]),_c('div',{staticClass:"comment"},[_c('div',{staticClass:"comment-l"},[_c('img',{attrs:{"src":require("./img/comment.png"),"alt":""}})]),_c('div',{staticClass:"comment-r"},[_vm._v(_vm._s(item.commentCount || 0))])])]),_c('div',{staticClass:"operate-r"},[_c('div',{class:[
                  'btn',
                  {
                    applying: item.activityStatus == 2,
                    going: item.activityStatus == 4,
                    back: item.hasFeedback && item.hasFeedback == 1,
                  },
                ]},[_c('span',[_vm._v(_vm._s(_vm.getOption(item.activityStatus, item.hasFeedback, item)))])])])])])])])}),0),_c('v-dialog',{staticClass:"dialog",attrs:{"showConfirmButton":false,"showCancelButton":false,"closeOnClickOverlay":true},model:{value:(_vm.showDialog),callback:function ($$v) {_vm.showDialog=$$v},expression:"showDialog"}},[_c('p',{staticClass:"dialog-content"},[_vm._v("您还未申请志愿者")]),_c('div',{staticClass:"dialog-btn",on:{"click":_vm.apply}},[_c('div',{staticClass:"btn"},[_vm._v("立即申请")])])])],1)
}
var staticRenderFns = []

export { render, staticRenderFns }